

















import { DPanelToolbar } from '@/components/Base'
import SystemStatus from '@/components/ControlPanel/SystemStatus.vue'
import LogsTerminal from '@/components/ControlPanel/LogsTerminal.vue'
import BrowserAlert from '@/components/Base/BrowserAlert.vue'

export default {
  name: 'ControlPanelPage',
  components: {
    DPanelToolbar,
    LogsTerminal,
    SystemStatus,
    BrowserAlert
  },
  data: () => ({
    activeTab: 0
  }),
  methods: {
    handleChangeTab(tabIndex) {
      this.activeTab = tabIndex
    }
  }
}
