
































































import { mapGetters, mapState } from 'vuex'
import { SentryMixin, SensorMixin } from '@/components/Mixins'
import DoughnutChart from '@/components/ControlPanel/DoughnutChart.vue'

export default {
  name: 'SystemStatus',
  components: {
    DoughnutChart
  },
  mixins: [ SentryMixin, SensorMixin ],
  computed: {
    ...mapState(['systemStats']),
    ...mapState('sensors', [
      'radarsSet', 'gps_compassesSet', 'discovair_sensorsSet', 'cannonsSet'
    ]),
    ...mapState('rf_sensors', ['rfSensorsSet', 'dsxSensorsSet']),
    ...mapState('cameras', ['camerasSet']),
    ...mapState(['connectionStatus']),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapGetters('system', ['isSocketConnected']),
    sentries() {
      // TODO sentry types
      return this.sentriesInSite(this.activeSiteId)
    },
    sensors() {
      return sentry_id => [
        // TODO where do we have sensor types for these
        ...Object.values(this.rfSensorsSet).filter((sensor: any) => sensor.sentry_id === sentry_id),
        ...Object.values(this.radarsSet).filter((sensor: any) => sensor.sentry_id === sentry_id),
        ...Object.values(this.dsxSensorsSet).filter((sensor: any) => sensor.sentry_id === sentry_id),
        ...Object.values(this.camerasSet).filter((sensor: any) => sensor.sentry_id === sentry_id),
        ...Object.values(this.gps_compassesSet).filter((sensor: any) => sensor.sentry_id === sentry_id),
        ...Object.values(this.discovair_sensorsSet).filter((sensor: any) => sensor.sentry_id === sentry_id),
        ...Object.values(this.cannonsSet).filter((sensor: any) => sensor.sentry_id === sentry_id)
      ]
    },
    cpuPercent(): string {
      if (this.systemStats.CPUPercentage === null) return '0'
      return parseFloat(this.systemStats.CPUPercentage).toFixed(1)
    },
    ramPercent(): string {
      if (this.systemStats.CPUPercentage === null) return '0'
      return parseFloat(this.systemStats.RAMPercentage).toFixed(1)
    },
    diskPercent(): string {
      if (typeof this.systemStats.DiskUsage === 'undefined') return '0'
      return parseFloat(this.systemStats.DiskUsage.used_percent).toFixed(1)
    },
    getSensorColor(sensor, sensorType): string{
      return this.$_statusColor(sensor, sensorType)
    }
  }
}
