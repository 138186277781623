
























import { mapGetters, mapState } from 'vuex'
import LogViewer from '@/components/ControlPanel/LogViewer.vue'

const props = {
  enabled: {
    type: Boolean,
    default: () => false
  }
}

export default {
  name: 'LogsTerminal',
  components: {
    LogViewer
  },
  props,
  data: () => ({
    wsConnection: null,
    logMessages: {},
    maxLogLines: 250,
    activeChannels: []
  }),
  computed: {
    ...mapState('system', ['coreSocketUrl']),
    ...mapGetters('auth', ['token']),
    channels() {
      return [ ...Object.keys(this.logMessages) ]
    },
    messages() {
      if (Object.keys(this.logMessages).length === 0) {
        return () => ([])
      }
      else {
        return (channel) => ([...this.logMessages[channel]])
      }
    },
    termStyles() {
      switch(this.activeChannels.length) {
        case 1:
        case 2:
        case 3:
          return `width: calc(100% - 1rem); height: calc((100vh - 270px) / ${this.activeChannels.length})`
        case 4:
        default:
          return `width: calc(50% - 1rem); height: calc((100vh - 270px) / 2)`
      }
    }
  },
  methods: {
    handleWebSocketConnect() {
      let connected = false
      try {
        this.wsConnection = new WebSocket(this.coreSocketUrl)
        connected = true
      }
      catch (e) {
        console.error('ControlPanelChannel subscribe failed: ' + e)
        connected = false
      }
      if (connected) this.handleWebSocketConnected()
      else setTimeout(this.handleWebSocketConnect, 2000) // retry connect after 2s
    },
    handleWebSocketConnected() {
      this.wsConnection.onopen = this.handleWebSocketReady
      this.wsConnection.onmessage = this.handleWebSocketMessage
      this.wsConnection.onclose = this.handleWebSocketClose
      this.wsConnection.onerror = this.handleWebSocketError
    },
    handleWebSocketReady() {
      this.wsConnection.send(JSON.stringify({
        "command": "authenticate",
        "token": this.token
      }))
      this.wsConnection.send(JSON.stringify({
        "command": "subscribe",
        "identifier": {
          "channel": "ControlPanelChannel"
        }
      }))
      this.wsConnection.send(JSON.stringify({
        "command": "control_panel",
        "message": "start"
      }))
    },
    handleWebSocketMessage(event) {
      const message = JSON.parse(event.data).message;
      if (typeof message === 'undefined') return
      const service = message.data.service_name
      if (!Object.keys(this.logMessages).includes(service)) {
        // setup new log buffer for this service
        this.logMessages = {
          ...this.logMessages,
          [service]: []
        }
      }
      if (this.logMessages[service].length >= this.maxLogLines) {
        // trim the log buffer to this.maxLogLines
        this.logMessages[service].splice(0, this.logMessages[service].length - this.maxLogLines + 1)
      }
      // add event to the log buffer
      this.logMessages[service].splice(this.logMessages[service].length, 0, {
        service: service,
        timestamp: message.data.data.ts,
        loglevel: message.data.data.level.toLowerCase(),
        message: message.data.data.msg || message.data.data.message,
        key: message.data.data.ts + '-' + Math.floor(Math.random() * 10000000) // much trouble with unique keys
      })
    },
    handleWebSocketError(e) {
      console.log('Logs Terminal WebSocket Error')
      console.error(e)
    },
    handleWebSocketClose(e) {
      this.handleWebSocketConnect()
    }
  },
  created() {
    this.handleWebSocketConnect()
  },
  mounted() {
    //
  },
  beforeDestroy() {
    this.wsConnection.close()
  }
}
