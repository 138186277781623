










import { isEqual } from 'lodash'

const props = {
  // log messages originate via props
  messages: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'LogViewer',
  props,
  data() {
    return {
      // log messages are displayed via lines array
      lines: [],
      autoscroll: true
    }
  },
  updated() {
    if (this.autoscroll) {
      // scroll to bottom
      this.$refs.logviewer.scrollTop = this.$refs.logviewer.scrollHeight
    }
  },
  mounted() {
    this.$refs.logviewer.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // if the viewer window is not resting at the bottom, pause autoscroll
      if (this.$refs.logviewer.clientHeight + this.$refs.logviewer.scrollTop < this.$refs.logviewer.scrollHeight) 
        this.autoscroll = false
      else 
        this.autoscroll = true
    }
  },
  watch: {
    messages: function(a, b) {
      // if the user is scrolled up then autoscroll is paused; dont update the log lines
      if (this.autoscroll && !isEqual(a, b)) {
        this.lines = a
      }
    }
  },
  beforeDestroy() {
    this.$refs.logviewer.removeEventListener('scroll', this.handleScroll)
  }
}
