




const props = {
  percent: {
    type: String,
  },
  width: {
    type: String,
    default: '140px'
  },
  height: {
    type: String,
    default: '140px'
  },
  defaultBarColor: {
    type: String,
    default: 'var(--dro-success)'
  },
  warningBarColor: {
    type: String,
    default: 'var(--dro-primary)'
  },
  errorBarColor: {
    type: String,
    default: 'var(--dro-error)'
  },
  barColour: {
    type: String,
    default: '#303030'
  },
  backgroundColor: {
    type: String,
    default: '#424242'
  }
}

export default {
  name: 'DoughnutChart',
  props,
  computed: {
    dataPercent(): string {
      return this.percent > 0 ? this.percent + '%' : ''
    },
    cssVariables(): string {
      return [
        '--donutWidth:' + this.width,
        '--donutHeight:' + this.height,
        '--donutPercent:' + this.percent + '%',
        (this.percent > 80 ?
            '--donutActiveBarColor:' + this.errorBarColor
            : this.percent > 50 ?
              '--donutActiveBarColor:' + this.warningBarColor
              : '--donutActiveBarColor:' + this.defaultBarColor
        ),
        '--donutBarColor:' + this.barColour,
        '--donutBackgroundColor:' + this.backgroundColor
      ].join(';')
    }
  }
}
